$sidebar-padding: 16px;
$sidebar-width: 360px;

$mobile-break-point: 1210px;

$background-color: #fff;
$background-color-highlight: #eee;
$font-color: #000;
$border-color: #c0c0c0;
$light-border-color: #e6e6e6;
$light-font-color: silver;
$notes-font-color: #696969;

$input-height: 30px;
$input-padding: 8px;
