@import 'src/styles/variables';

$scrollbar-width: 4px;
$logo-height: 60px;
$filter-button-height: 24px;

.sidebar {
    overflow-y: scroll;
    width: $sidebar-width;
    border-right: 1px solid $border-color;
    height: 100vh;
    padding: 0 $sidebar-padding;
    z-index: 20;
    background: $background-color;

    @media only screen and (max-width: $mobile-break-point) {
        height: var(--doc-height);

        position: absolute;
        background: $background-color;
        left: -$sidebar-width - 2 * $sidebar-padding;
        transition: 0.4s;

        &.visible {
            left: 0;
        }
    }

    a {
        color: $font-color;
        text-decoration: none;
    }

    header {
        position: absolute;
        background: $background-color;
        padding-top: $sidebar-padding;
        width: $sidebar-width;
        z-index: 5;

        @media only screen and (max-width: $mobile-break-point) {
            position: fixed;
        }

        img {
            height: $logo-height;
            float: left;
        }

        a {
            float: right;
            width: $sidebar-width - $logo-height;
            line-height: 20px;
            letter-spacing: 0.5px;
            word-spacing: 2px;
            font-size: 22px;
            font-weight: 600;
            margin-top: 14px;
            text-align: center;

            span {
                font-size: 12px;
                font-weight: 200;
            }
        }

        .search {
            clear: both;
            padding: 2 * $sidebar-padding 0 $sidebar-padding / 2 0;

            $input-width: $sidebar-width - $sidebar-padding / 2 - 2px - $scrollbar-width - 2 * $input-padding;

            input {
                padding: 0 $input-padding;
                height: $input-height;
                width: $input-width;
                border-radius: 8px;
                border: 1px solid $border-color;
                font-family: Martel, serif;
                outline: none;
            }

            > img {
                padding: 9px;
                position: absolute;
                left: $input-width - 12px;
                top: $sidebar-padding + $logo-height + 2 * $sidebar-padding + 2px;
                height: 12px;
                opacity: 0.3;
                cursor: pointer;

                &:hover {
                    opacity: 0.6;
                }
            }

            .button-wrapper {
                text-align: center;
                width: $input-width + 2 * $input-padding + 2px;

                button {
                    background: transparent;
                    border: 0;
                    font-family: Martel, serif;
                    font-weight: 200;
                    font-size: 14px;
                    height: $filter-button-height;
                    cursor: pointer;

                    @media only screen and (min-width: $mobile-break-point - 1px) {
                        color: $light-font-color;
                    }

                    &:hover {
                        color: $font-color;
                    }
                }

                /*img {
                    padding: 9px;
                    position: absolute;
                    left: $input-width / 2 + 70px;
                    margin-top: -3px;
                    height: 12px;
                    opacity: 0.3;
                    cursor: pointer;

                    &:hover {
                        opacity: 0.6;
                    }
                }*/
            }
        }

        &.active::after {
            content: '';
            position: absolute;
            bottom: -$sidebar-padding;
            width: $sidebar-width;
            height: $sidebar-padding;
            background: linear-gradient(
                white,
                rgba(255, 255, 255, 0.001)
            ); /* transparent keyword is broken in Safari */
        }
    }

    .recipe-list,
    .no-matches {
        margin-top: $sidebar-padding + $logo-height + 2 * $sidebar-padding + $input-height + $filter-button-height + 2px +
            $sidebar-padding;
        padding: 0;

        li {
            list-style: none;
            color: $font-color;
            text-decoration: none;
            cursor: pointer;

            img {
                position: relative;
                float: right;
                width: 14px;
                margin-top: 4px;
                opacity: 0.4;
            }

            span {
                float: right;
                color: $light-font-color;
                width: 32px;
                text-align: right;
            }

            &:hover,
            &.active {
                font-weight: 700;

                img {
                    opacity: 1;
                }

                span {
                    font-weight: 200;
                    color: $font-color;
                }
            }
        }
    }
}

@media print {
    .sidebar {
        display: none;
    }
}
