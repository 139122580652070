@import 'src/styles/variables';

.recipe-wrapper {
    @media not print {
        height: 100vh; /* fallback for Js load */
        height: var(--doc-height);
        overflow-y: scroll;
    }

    @media print {
        padding-top: 16px;
    }
}

.recipe {
    position: relative;

    @media not print {
        &.even,
        &.odd {
            margin: 4px 40px 40px 40px;
        }
    }

    @media print {
        &.even {
            margin-right: 40px;
        }

        &.odd {
            margin-left: 40px;
        }
    }

    h1 {
        margin-top: 0;
    }

    @media not print {
        h1 {
            margin-top: 22px;
        }
    }

    /*body.compact-ingredients h2 {
        margin-top: 12px;
        margin-bottom: 0;
    }*/

    .login,
    .favorite {
        position: absolute;
        width: 24px;
        height: 24px;
        top: 6px;
        right: 0;
        cursor: pointer;

        @media only screen and (max-width: $mobile-break-point) {
            position: fixed;
            top: 0;
            width: 20px;
            padding: 12px;
        }

        @media print {
            display: none;
        }
    }

    .favorite {
        right: 38px;
    }

    p {
        margin-top: 22px;
    }

    @media print {
        a {
            color: #000;
            text-decoration: none;
        }
    }

    @media not print {
        span.page-reference {
            display: none;
        }
    }

    .columns {
        display: flex;
    }

    .columns > div:first-child {
        flex: 20%;
    }

    .columns > div:last-child {
        flex: 80%;
        padding-left: 72px;
    }

    &.wide-ingredients {
        .columns > div:first-child {
            flex: 35%;
        }

        .columns > div:last-child {
            flex: 65%;
            padding-left: 36px;
        }
    }

    .columns h2 {
        white-space: nowrap;
    }

    > div {
        display: flex;
        align-content: space-between;
    }

    > div > div {
        flex: 1 0 auto;
        /*border: 1px solid blue;*/
    }

    > div > div:last-of-type {
        justify-content: flex-end;
        /*border: 2px solid green*/
    }

    /*table {
    border: 1px solid red;
}*/

    table.no-amount tr > td:nth-child(1) {
        display: none;
    }

    table.no-amount tr > td:nth-child(2) {
        padding-left: 0;
    }

    th,
    td {
        padding: 2px 0;
        text-align: left;
        line-height: 125%;
    }

    body.compact-ingredients th {
        font-size: 13px;
    }

    body.compact-ingredients td {
        font-size: 12px;
    }

    th:first-child,
    td:first-child {
        text-align: right;
        white-space: nowrap;
        font-weight: 600;
    }

    th:nth-child(2),
    td:nth-child(2) {
        padding-left: 8px;
    }

    .columns > div:nth-child(2) table {
        th {
            font-weight: 700;
        }
        td {
            font-weight: 200;
        }
    }

    ul {
        padding-left: 24px;
    }

    ol {
        counter-reset: list-counter;
        padding-inline-start: 0;
    }

    ol li {
        position: relative;
        padding: 4px 0 4px 30px;
        list-style: none;
        line-height: 125%;
    }

    ol li:before {
        position: absolute;
        top: 50%;
        left: 0;
        width: 20px;
        height: 20px;
        margin-top: -10px;
        color: #000;
        text-align: center;
        background-color: $background-color-highlight;
        content: counter(list-counter, decimal);
        counter-increment: list-counter;
        font-size: 13px;
        font-weight: 600;
        line-height: 140%;
        -webkit-border-radius: 50%;
        border-radius: 50%;

        @media not print {
            font-size: 14px;
        }
    }

    ol li strong {
        font-weight: 600;

        @media not print {
            font-weight: 700;
        }
    }

    > table,
    div.columns > div > table {
        margin-top: 32px;
        margin-left: -10px;
    }

    > table th,
    > table td,
    div.columns > div > table th,
    div.columns > div > table td {
        text-align: center;
        padding: 0 10px;
    }

    .notes {
        color: $notes-font-color;
        display: block;
        margin-top: 48px;
        font-style: italic;

        p {
            margin: 2px 0 2px 8px;
            font-size: 13px;

            @media not print {
                font-size: 14px;
            }

            &:first-child {
                text-indent: -8px;
            }
        }
    }

    footer {
        position: fixed;
        bottom: 0;
        padding: 0;
        margin: 0;
        right: 0;
        text-align: right;
    }

    @media only screen {
        footer {
            color: $light-font-color;
            font-size: 16px;
            font-weight: 200;
            padding: 0 24px 2px 0;
        }
    }

    @media only print {
        &.even footer {
            left: 0;
            text-align: left;
        }
    }
}
