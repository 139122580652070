@import 'src/styles/variables';

.registry {
    columns: 2;
    column-gap: 48px;
    margin: 0 40px;

    h2 {
        font-size: 16px;
        margin-top: 0;
    }

    h3 {
        font-size: 14px;
        margin-top: 0;
        margin-bottom: 6px;
    }

    .group {
        margin-bottom: 24px;
        font-size: 13px;
        line-height: 125%;
    }

    hr {
        margin: -16px 0 24px 0;
    }

    .group hr {
        margin: -16px 0 8px 0;
    }

    .group ul {
        margin-block-start: 0;
        margin-block-end: 0;
        padding-inline-start: 0;
    }

    .sub-group {
        margin-top: -12px;
    }

    .group a {
        display: flex;
        text-decoration: none;
        color: #000;
        padding: 2px 0;
    }

    .group .name,
    .group .page {
        flex: 1 0 auto;
    }

    .group .name {
        padding-right: 12px;
    }

    .group .page {
        text-align: right;
        width: 2em;
    }

    .dots {
        flex: 0 1 auto;
        overflow: hidden;
    }

    .dots::before {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: clip;
        opacity: 0.4;
        content: '. . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .';
    }
}
