@import 'src/styles/variables';

.Dropdown-root {
    margin-bottom: 20px;
    font-weight: normal;

    .Dropdown-control,
    .Dropdown-menu {
        border: 1px solid $border-color;
        border-radius: 4px;
    }

    &.is-open .Dropdown-control {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    &.is-open .Dropdown-menu {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    .Dropdown-control {
        padding: 4px 10px;
    }

    .Dropdown-placeholder {
        color: $font-color;
    }

    .Dropdown-option {
        color: $font-color;
        padding: 4px 10px;

        &.is-selected,
        &:hover {
            background: $background-color-highlight;
        }
    }

    .Dropdown-arrow-wrapper img {
        position: absolute;
        right: 10px;
        top: 5px;
        width: 8px;
        transform: rotate(90deg);

        &.open {
            transform: rotate(270deg);
        }
    }

    &.is-open .Dropdown-arrow-wrapper img {
        top: 6px;
    }
}
