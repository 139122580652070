@import 'src/styles/variables';

$filterbar-width: 222px;
$sidebar-padding: 16px;
$scrollbar-width: 4px;
$logo-height: 60px;
$search-input-height: 32px;
$search-input-padding: 8px;
$filter-button-height: 24px;

.filterbar {
    overflow-y: scroll;
    width: $filterbar-width;
    border-right: 1px solid $border-color;
    height: 100vh;
    height: var(--doc-height);
    padding: 0 $sidebar-padding;
    position: absolute;
    background: $background-color;
    z-index: 15;
    left: $sidebar-width - $filterbar-width;
    transition: 0.4s;

    &.visible {
        left: $sidebar-width + 2 * $sidebar-padding + 1px;
    }

    &.hidden {
        display: block;
    }

    @media only screen and (max-width: $mobile-break-point) {
        &.hidden {
            left: -$filterbar-width - 2 * $sidebar-padding;
        }
    }

    h2 {
        font-size: 16px;
        margin-top: 16px;
        user-select: none;
    }

    h3 {
        margin: 0;
        user-select: none;

        img {
            cursor: pointer;
            width: 8px;
            float: right;
            margin: 4px 11px 0 0;
            opacity: 0.3;

            &:hover {
                opacity: 1;
            }
        }

        &:hover {
            img {
                opacity: 1;
            }
        }

        &.favorites {
            margin-bottom: 12px;
            cursor: pointer;

            img {
                float: none;
                width: 16px;
                vertical-align: middle;
                margin-top: -4px;
                opacity: 1;
            }
        }
    }

    .tags {
        user-select: none;

        h3.accordion {
            margin: 0 0 4px 0;
            cursor: pointer;

            img {
                width: 8px;
                float: left;
                margin: 2px 4px 0;

                &.expanded {
                    transform: rotate(90deg);
                    margin-top: 1px;
                }
            }
        }

        ul {
            margin-top: 4px;
            margin-bottom: 20px;
            padding: 0;
            display: none;

            &.expanded {
                display: block;
            }
        }

        li {
            list-style: none;
            padding-left: 16px;
        }

        button {
            width: 16px;
            height: 16px;
            border-radius: 100%;
            border: 1px solid silver;
            text-align: center;
            margin-right: 2px;
            line-height: 16px;
            font-size: 16px;
            padding: 0;
            font-family: Courier, serif;
            opacity: 0.6;
            cursor: pointer;

            &.active {
                opacity: 0.6;

                &:hover {
                    opacity: 1;
                }
            }

            &:hover {
                opacity: 0.6;
            }

            span {
                display: inline-block;
                margin-top: -2px;
                vertical-align: top;
            }

            &.included {
                &.active {
                    background-color: rgb(163, 194, 147);
                    border-color: rgb(44, 102, 45);
                    color: rgb(44, 102, 45);
                }

                @media only screen and (min-width: $mobile-break-point - 1px) {
                    &:hover {
                        background-color: rgb(163, 194, 147);
                        border-color: rgb(44, 102, 45);
                        color: rgb(44, 102, 45);
                    }
                }

                span {
                    margin-top: -0.7px;
                }
            }

            &.excluded {
                &.active {
                    background-color: rgb(224, 180, 180);
                    border-color: rgb(159, 58, 56);
                    color: rgb(159, 58, 56);
                }

                @media only screen and (min-width: $mobile-break-point - 1px) {
                    &:hover {
                        background-color: rgb(224, 180, 180);
                        border-color: rgb(159, 58, 56);
                        color: rgb(159, 58, 56);
                    }
                }
            }
        }

        .label {
            margin-left: 8px;
            cursor: pointer;
        }
    }
}

@media print {
    .filterbar,
    .filterbar.hidden {
        display: none;
    }
}
