@import 'src/styles/variables';

$modal-padding: 16px;

.modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(192, 192, 192, 0.4);
    opacity: 0;
    visibility: hidden;
    transform: scale(1.1);
    transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
    z-index: 20;

    &.visible {
        opacity: 1;
        visibility: visible;
        transform: scale(1);
        transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
    }

    .wrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        padding: $modal-padding $modal-padding ($modal-padding + $input-height) $modal-padding;
        min-width: 400px;
        max-width: 600px;
        min-height: 100px;
        border-radius: 8px;

        h2 {
            margin: 0;
        }

        .content {
            form {
                padding: $modal-padding 0;
                display: flex;

                .group {
                    flex: 50%;
                    justify-content: space-between;
                    margin-right: 16px;

                    &:last-of-type {
                        margin-right: 0;
                    }

                    &.error {
                        label {
                            color: rgb(159, 58, 56);

                            span {
                                margin-left: 8px;
                            }
                        }

                        input {
                            border-color: rgb(159, 58, 56);
                        }
                    }

                    &:nth-child(2) {
                        input {
                            margin-right: 0;
                        }
                    }
                }

                &.loading {
                }
            }

            label {
                display: block;
                margin-right: $modal-padding;
            }

            input {
                box-sizing: border-box;
                padding: 0 $input-padding;
                height: $input-height;
                width: 100%;
                border-radius: 8px;
                border: 1px solid $border-color;
                font-family: Martel, serif;
                outline: none;
                margin-right: 2 * $modal-padding;
            }
        }

        .buttons {
            position: absolute;
            bottom: $modal-padding;
            right: $modal-padding;
            text-align: right;
            width: 100%;

            button {
                padding: 0 8px;
                border-radius: 4px;
                background: $background-color;
                border: 1px solid $border-color;
                font-family: Martel, serif;
                outline: none;
                height: $input-height;
                margin-left: $modal-padding;

                &:hover {
                    background: $background-color-highlight;
                }
            }
        }
    }
}
