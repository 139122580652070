@import 'src/styles/variables';

.title-page {
    text-align: center;

    h1 {
        font-size: 26px;
        margin-top: 96px;
    }

    img {
        width: 360px;
        margin: 48px;
    }

    p {
        font-size: 18px;
    }

    .version {
        position: absolute;
        left: 0;
        bottom: 12px;
        font-size: 10px;
        width: 100%;
        color: $notes-font-color;
    }
}
