@import 'src/styles/variables';

* {
    -webkit-tap-highlight-color: transparent;
}

:root {
    --doc-height: 100%;
}

html,
body,
#root {
    padding: 0;
    margin: 0;
    height: 100vh; /* fallback for Js load */
    height: var(--doc-height);
}

body {
    background: $background-color;
    color: $font-color;
    font-family: Martel, serif;
    font-size: 13px;
    font-weight: 200;
    min-width: 800px;
    margin: 0;
    height: 100%;

    @media not print {
        font-size: 14px;
    }

    &.compact {
        font-size: 12px;
    }
}

.grid {
    height: 100%;

    @media only screen and (min-width: $mobile-break-point - 1px) {
        display: flex;

        .content {
            flex: 1;
        }
    }
}

h1 {
    font-size: 22px;
    font-weight: 800;
    text-align: center;
    letter-spacing: 0.5px;
    word-spacing: 6px;
    margin-top: 0;
    margin-bottom: 24px;
}

h2 {
    font-size: 14px;
    margin-top: 22px;
}

h3 {
    font-size: 13px;
    margin-top: 13px;
}

@media not print {
    h2 {
        font-size: 16px;
    }

    h3 {
        font-size: 14px;
        margin-top: 14px;
    }
}

.menu-icon {
    position: fixed;
    top: 0;
    left: 0;
    width: 20px;
    padding: 12px;

    @media print {
        display: none;
    }
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw; /* fallback for Js load */
    width: var(--doc-width);
    height: 100vh; /* fallback for Js load */
    height: var(--doc-height);
    background: silver;
    opacity: 0.4;
    z-index: 10;
    display: none;

    @media only screen and (max-width: $mobile-break-point) {
        &.sidebarVisible {
            display: block;
        }
    }

    &.filterbarVisible {
        display: block;
    }
}

.hidden {
    display: none;
}

/*
@media print {
    body {
        margin: 16px;
    }
}*/
